<template>
  <!-- fade -->
  <!--<div class="e-picture" >-->
    <picture :src="empty"
             :alt="alt"
             :class="(resizing === 'fill' ? ratio : '') +
                     (border ? ' border border-solid border-gray-400' : '') +
                     (!noBg ? ' bg-surface-overlay' : '') +
                     (!isChat && resizing !== 'cover' ? ' relative' : '') +
                     (!isChat && resizing !== 'cover' ? ' absolute' : '') +
                     (zoom === true ? ' swiper-zoom-container' : '') +
                     (!isChat ? ' swiper-zoom-target e-picture-img block min-h-0 w-full' : '')">
      <source
        v-for="(source, index) in _srcset.sources"
        :key="_srcset.base + index"
        :data-srcset="!forceLoad ? source.srcset : false"
        :srcset="forceLoad ? source.srcset : false"
        :type="source.type"
        :media="source.media"/>
      <img
        ref="img"
        @load="onLoad"
        :width="attributes.width" :height="attributes.height"
        :src="forceLoad ? _srcset.base : empty"
        :data-src="!forceLoad ? _srcset.base : false"
        :class="{
          'h-full object-cover' : this.resizing === 'fill',
          'h-full object-contain' : this.resizing === 'fit-contain',
          'lazyload' : lazy && !forceLoad,
          'h-full object-cover object-center' : this.resizing === 'cover',
          'w-full text-transparent border-0' : !isChat,
          'object-bottom': bottom
        }"
        :alt="alt"/>
      <slot />
      <!--:data-lowsrc="blurUp ? lowres : false"-->
    </picture>
  <!--</div>-->
</template>

<script>
import {srcset, srcsetRatio} from "assets/js/utils/srcset";
import {DEFAULT_IMG} from "assets/js/constants/constant";
import imgProxyURL from 'assets/js/utils/imgProxy';

export default {
  props: {
    events : Boolean,
    sizes: {
      type: Array
    },
    src: {
      type: String,
      default: '',
    },
    noBg: {
      type: Boolean,
      default: false,
    },
    blurUp: {
      type: Boolean,
      default: false
    },
    resizing: {
      type: String,
      default: 'fill'
    },
    alt : {
      type : String,
      default : ''
    },
    forceLoad : {
      type : Boolean,
      default : false
    },
    zoom : Boolean,
    isChat: false,
    border: Boolean,
    bottom: Boolean,
    rotation: {
      type: [String, Number],
      default: 0
    }
  },
  computed: {
    attributes() {
      let size = null;
      for (let breakpoint in this._srcset.attributes) {
        size = this._srcset.attributes[breakpoint];
        if(breakpoint === this.$screen.current.name) {
          break;
        }
      }
      return {
        width : size ? size.width : 800,
        height : size ? size.height : 600
      }
    },
    empty () {
      return DEFAULT_IMG;
    },
    lazy() {
      return this.src !== null && this.src.indexOf('://') > -1;
    },
    ratio() {
      return srcsetRatio(this.sizes);
    },
    lowres() {
      return process.env.imgProxy + imgProxyURL(this.src, 50, 50, 'webp', 'fit', 'ce',  this.rotation)
    },
    _srcset() {
      const realFit = this.resizing !== 'cover' && this.resizing !== 'fit-contain' ? this.resizing : 'fit';
      return this.src !== null ? srcset(this.sizes,this.src, realFit, this.rotation) :  {}
    },
  },
  methods : {
    onLoad() {
      if(this.events) {
        this.$emit('loaded');
        this.$emit('ready');
      }
    }
  }
};
</script>

<style scoped lang="scss">
  .swiper-zoom-container {
    & > .object-cover {
      object-fit: cover;
    }
  }

</style>
