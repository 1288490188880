<template>
  <div class="w-full flex flex-col h-full relative" :class="{'back' : isBack,'xs:pb-footer-safe' : hasFooter, 'xs:pb-safe' : !hasFooter}">
    <TopNav :sticky="false">
      <template #leftButtons v-if="depth !== 0 || popin">
        <IconButton icon="arrow-left"
                    small
                    @click.native="onClickBack"/>
      </template>
      {{ $t(title) }}
    </TopNav>
    <div v-if="addSearch" class="p-8">
      <SearchInput :placeholder="$t('search.brand')"
                   :pagination="false"
                   class="w-full"
                   :watch-query="false"
                   v-model="query"
                   :small="true"/>
    </div>
    <div v-if="infos" class="px-4 py-2 flex flex-col border-b border-surface-light">
      <span class="text-16 text-black-light">{{ $t(infos.label) }}</span>
      <nuxt-link class="text-16 text-primary" v-if="infos.cta" :to="infos.to">{{ $t(infos.cta) }}</nuxt-link>
    </div>
    <div class="flex-auto overflow-y-auto overflow-x-hidden min-h-0 relative flex ">
      <transition :name="transition">
        <DeepSelectorSlide :hasCancel="hasCancel"
                           :full-list="fullList"
                           :hint="hint"
                           :countable="countable?.countable"
                           :list-count="listCount"
                           :isMultiple="isMultiple" :key="depth" :is-filtered="query !== null"
                           :is-preview-list="fullList && fullList.length > 0"
                           :activeSlug="levelSlug" :data="level"
                           :itemTemplate="itemTemplate || 'DeepSelectorItem'"
                           :selected="selected"
                           :hasFooter="hasFooter"
                           @select="onLevel" @skip="onSkip" @all="useFullList = true"/>
      </transition>
    </div>
    <div class="p-4 flex flex-col" v-if="isMultiple">
      <Button class="whitespace-nowrap flex-1 text-16 py-6 w-full"
              @click.native="onValidate"
              color="primary">
        {{ $t('deepSelector.validate') }}
      </Button>
    </div>
  </div>
</template>

<script>
import DeepSelectorSlide from "~/components/DeepSelector/DeepSelectorSlide"
import BackIcon from "~/assets/svg/back.svg?inline";
import ArrowLeftIcon from "~/assets/svg/arrow-left.svg?inline";
import Close from "~/assets/svg/delete.svg?inline";
import SearchInput from "@/components/SearchInput"
import Back from "@/components/buttons/Back"
import {PopinBack} from "@/mixins/popinBack";
import TopNav from "@/components/global/TopNav.vue"
import IconButton from "@/components/buttons/IconButton.vue"
import Button from "@/components/buttons/Button.vue"
import {getIsAllItemsToRemove, getItemsToAdd, getItemsToRemove, toDeepItem} from "assets/js/services/selector";

export default {
  mixins: [PopinBack],
  components: {
    IconButton,
    TopNav,
    DeepSelectorSlide,
    BackIcon,
    SearchInput,
    Back,
    Close,
    ArrowLeftIcon,
    Button
  },
  props: {
    hasCancel: Boolean,
    popin: Boolean,
    hasFooter: {
      type: Boolean,
      default: true
    },
    label: {
      type: String
    },
    hint: String,
    value: {
      type: [Object, Array]
    },
    tree: {
      type: Array,
      default: () => []
    },
    fullList: {
      type: Array,
    },
    isMultiple: {
      type: Boolean
    },
    hasSearch: {
      type: Boolean
    },
    infos: {
      type: Object
    },
    type: {
      type: String | Number
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    itemTemplate: {
      type: String,
      default: 'DeepSelectorItem'
    },
    countable: Object,
  },
  data: () => ({
    // when user click on "see more brands" we switch to true
    useFullList: false,
    query: null,
    current: null,
    selected: null,
    isBack: false,
    queue: [],
    memory: [],
    levelSlug: null,
    addSearch: false,
    transition: 'slide-to-left',
    listCount: {loading: true}
  }),
  created() {
    this.memory = this.value && this.value._memory ? this.value._memory : [];
    if (this.memory.length) {
      const queue = [...this.memory];
      queue.pop();
      this.queue = queue;
    }
    if (this.value) {
      this.selected = this.value;
    } else if (this.isMultiple) {
      this.selected = [];
    }

    this.addSearch = this.hasSearch
  },
  async mounted() {
    if (this.countable?.countable) {
      const listCount = await this.$api.$get(process.env.apiUrl + '/api/v2/search/count_props', {
        params: {
          ...this.countable.query,
          ...this.countable.metasParent && {metasParent: this.countable.metasParent},
          except: this.countable.type
        }
      });

      if (!listCount?.error) {
        this.listCount = listCount
      } else {
        this.listCount = {loading: false}
      }
    }
  },
  computed: {
    title() {
      const last = this.memory[this.memory.length - 1];
      if (last) {
        const split = last.split('-');
        return split[split.length - 1];
      } else {
        return this.label;
      }
    },
    depth() {
      return this.memory.length;
    },
    level() {
      let list;
      if (this.current) {
        list = [this.current];
      } else if (this.useFullList === true || (this.query && this.hasSearch && this.fullList && this.fullList.length)) {
        list = this.fullList;
      } else {
        list = this.tree;
      }

      if (this.hasSearch || this.addSearch) {
        list = [...list];
        list = list.map(listItem => {
          const copy = {...listItem};
          copy.children = [...copy.children];
          if (this.query) {
            copy.children = listItem.children.filter(item => {
              let label = item.name;
              label = label.toLowerCase();
              return label.indexOf(this.query.toLowerCase()) > -1;
            });
          } else {
            //copy.children.length = copy.children.length;//Math.min(copy.children.length , 10);
          }
          return copy;
        });
      }
      return list;
    }
  },
  watch: {
    queue(value, last) {
      this.isBack = value.length <= last.length;

      if (value.length) {
        this.current = toDeepItem(value, this.fullList, this.tree);
      } else {
        this.current = null;
      }

    }
  },
  beforeDestroy() {
    this.query = null;
    this.current = null;
    this.selected = null;
    this.isBack = false;
    this.queue = [];
    this.memory = [];
    this.levelSlug = null;
  },
  methods: {
    onClickBack() {
      if (this.depth !== 0) {
        this.back()
      } else if (this.popin) {
        if (this.isMultiple && this.selected) {
          this.onValidate()
        } else {
          this.onClose()
        }
      }
    },
    onSkip() {
      //  if you change args, you have to change the object in Product/add
      this.$emit('complete', {
        name: this.$t('deepselector.skip'),
        empty: true
      });
    },
    onClose() {
      this.$emit('close');
    },
    back() {
      const copy = [...this.queue];
      copy.pop();
      this.queue = copy;
      this.memory = copy;
      this.transition = 'slide-to-left-reverse'
    },
    onValidate() {
      this.disableRouteOnClose = true;
      this.$emit('complete', this.selected);
    },

    beforeDestroy() {
      this.$emit('destroyed');
    },
    onLevel: function (slugId, isLast) {
      //this.addSearch = slugId.search('filters.brand') > -1
      this.transition = 'slide-to-left'
      if (isLast) {

        if (this.isMultiple) {

          if (this.selected && this.selected.empty) {
            this.selected = []
          }

          if (this.selected && Array.isArray(this.selected)) {

            let selectedItem = toDeepItem([...this.queue, slugId], this.fullList, this.tree)

            const found = this.selected.find(item => selectedItem.id === item.id);
            if (found) {
              selectedItem = found
            }

            const parentItem = toDeepItem([...this.queue], this.fullList, this.tree)

            const canAddItem = this.max === -1 || this.selected.length < this.max;
            const canRemoveItem = this.min === -1 || this.selected.length > this.min;

            if (!found && canAddItem) {
              if (selectedItem.isAll) {
                const itemsToAdd = getItemsToAdd(parentItem, this.selected)
                this.selected = [...this.selected, ...itemsToAdd];
              } else {
                this.selected = [...this.selected, selectedItem];
              }
            } else if (canRemoveItem) {

              if (selectedItem.isAll) {
                const itemsToRemove = getItemsToRemove(parentItem)
                this.selected = [...this.selected].filter(item => !itemsToRemove.includes(item))
              } else {
                let itemsToRemove = [selectedItem]
                const isAllItemsToRemove = getIsAllItemsToRemove(this.queue, this.fullList, this.tree)
                if (isAllItemsToRemove?.length) {
                  itemsToRemove = [...itemsToRemove, ...isAllItemsToRemove]
                }

                this.selected = [...this.selected].filter(item => !itemsToRemove.includes(item));
              }
            }
          }

        } else {
          const lastQueue = [...this.queue, slugId];
          const lastMemory = [...this.memory];
          lastMemory[lastQueue.length - 1] = lastQueue[lastQueue.length - 1];
          this.selected = {
            ...toDeepItem([...this.queue, slugId], this.fullList, this.tree),
            _memory: lastMemory.length > 1 ? lastMemory : []
          };
          //this.queue = [];

          this.$nextTick(() => {
            this.$emit('complete', this.selected);
          });
        }
      } else {
        this.queue = [...this.queue, slugId];
        const copy = [...this.memory];
        copy[this.queue.length - 1] = this.queue[this.queue.length - 1];
        this.memory = copy;
      }
    }
  }
}
</script>
