<template>
  <button v-bind="$listeners"
          class="flex w-full px-4">
    <div class="flex border-b border-gray-400 border-solid px-6 w-full items-center"
         :class="{'py-8': !image, 'py-2': image, 'border-b-2': item.isFilterLabel}">
      <span v-if="item.hexadecimalCode" class="block mr-4 w-14 h-14 border border-solid border-gray-400 rounded-full"
            :style="{background: item.hexadecimalCode}"/>
      <div v-if="image" class="h-[80px] w-[80px] bg-white overflow-hidden mr-4">
        <Picture class="w-[80px] h-[80px]"
                 :src="image"
                 :sizes="['160:160']"
                 :resizing="'fill'"
                 no-bg
                 force-load />
      </div>
      <div class="flex flex-col mr-auto text-left">
        <div class="flex items-center">
          <span v-once class="leading-none text-16 font-bold">{{ item.name }}</span>
          <span v-once v-if="countable && item.count > 0" class="text-14 text-black-light inline-block ml-4">({{ item.count > 100 ? '+100' : item.count }})</span>
          <span v-once v-if="countable && item.loading" class="loader-fake-text animate-pulse w-12 mb-0 ml-4"/>
        </div>
        <span v-once v-if="item.description" class="text-14 pt-2 text-black-light">{{ item.description }}</span>
      </div>
      <span v-once v-if="active && item.children !== undefined">{{ selected.name }}</span>
      <CheckBox v-if="!item.children && !item.isFilterLabel && isMultiple" :value="active" outline-dark small />
      <span v-if="!item.children && !isMultiple"
            class="flex items-center justify-center flex-shrink-0 rounded-full ml-4 w-8 h-8  border-2"
            :class="{'border-secondary-900' : !active, 'bg-primary-500 border-primary-500' : active}">
      </span>
      <Arrow v-if="item.children" class="w-6 h-6 mx-6 flex-shrink-0 text-black"/>

    </div>

  </button>
</template>

<script>
import Arrow from "~/assets/svg/arrow.svg?inline";
import Checkbox from "~/assets/svg/checkbox.svg?inline";
import CheckBox from "@/components/form/CheckBox.vue"
import Picture from "@/components/Picture.vue"

export default {
  components: {Picture, CheckBox, Arrow,Checkbox},
  props: {
    activeSlug: {
      type: null
    },
    selected: {
      type: [Object, Array]
    },
    isMultiple: {
      type: Boolean
    },
    countable: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {

  },
  computed: {
    active() {
      if (this.selected && this.selected.length) {
        return this.selected.some(item => (item.id + '-' + item.name) === (this.item.id + '-' + this.item.name));
      } else if (this.selected) {
        return (this.selected.id + '-' + this.selected.name) === (this.item.id + '-' + this.item.name);
      } else {
        return false;
      }
    },
    image() {
      let image = null
      if (this.item.type === 'genders') {
        image = process.env.staticAssets + '/site/genders/' + this.item.id + '.png'
      } else if (this.item.iconImage) {
        image = this.item.iconImage
      }
      return image
    }
  }
}
</script>
