<template>
  <nuxt-link v-bind="$attrs"  >
    <slot />
  </nuxt-link>
</template>

<script>
export default {
  props : []
}
</script>
