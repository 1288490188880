<template>
  <button v-bind="$listeners"
          class="flex text-2xl w-full text-left p-6 mb-4 items-center bg-form-bg rounded-sm">
    <SvgIcon v-if="item.depth && item.depth === 2" class="w-8 h-10 object-contain mr-4" :name="`catalog/${item.id}`"/>
    <div class="flex flex-col mr-auto">
      <div>
        <span v-once class="leading-none text-16">{{ item.name }}</span>
        <span v-once v-if="countable && item.count > 0" class="text-14 text-black-light inline-block ml-4">({{ item.count > 100 ? '+100' : item.count }})</span>
        <span v-once v-if="countable && item.loading" class="loader-fake-text animate-pulse w-12"/>
      </div>
      <span v-once v-if="item.description" class="text-14 pt-2 text-black-light">{{ item.description }}</span>
    </div>
    <span v-once v-if="active && item.children !== undefined">{{ selected.name }}</span>

    <span v-if="!item.children && !isMultiple"
          class="flex items-center justify-center flex-shrink-0 rounded-full ml-4 w-8 h-8  border-2 border-secondary-900">
          <Checkbox v-if="active" class="w-5 h-5 text-white" />
    </span>
    <Arrow v-if="item.children" class="w-6 h-6 mx-6 flex-shrink-0 text-black"/>
  </button>
</template>

<script>
import Arrow from "~/assets/svg/arrow.svg?inline";
import Checkbox from "~/assets/svg/checkbox.svg?inline";
import SvgIcon from "@/components/SvgIcon.vue"

export default {
  components: {SvgIcon, Arrow,Checkbox},
  props: {
    activeSlug: {
      type: null
    },
    selected: {
      type: [Object, Array]
    },
    isMultiple: {
      type: Boolean
    },
    countable: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    active() {
      if (this.selected && this.selected.length) {
        return this.selected.some(item => (item.id + '-' + item.name) === (this.item.id + '-' + this.item.name));
      } else if (this.selected) {
        return (this.selected.id + '-' + this.selected.name) === (this.item.id + '-' + this.item.name);
      } else {
        return false;
      }
    }
  }
}
</script>
