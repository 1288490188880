<template>
  <div class="e-deep-selector-slide absolute sm:relative w-full h-full sm:h-auto bg-white flex flex-col">
    <div class="pb-4 h-full" :class="{'pt-4 px-4' : itemTemplate === 'DeepSelectorItemNav', 'pb-footer-safe': hasFooter}">
      <div class="h-full">
        <DynamicRecycleView :items="mergedList"
                            :min-item-size="40"
                            :finished="!hasMore"
                            :key-field="'key'"
                            :on-loadmore="hasFullList ? onFullList : null"
                            class="scroller h-full pb-footer">
          <template #header>
            <span class="p-sm-full text-16 text-black-light block uppercase font-thin" v-if="hint">{{ hint }}</span>
          </template>
          <template v-slot="{ item, index, active }">
            <DynamicRecycleViewItem :item="item"
                                    :active="active"
                                    :data-index="index">
              <span v-if="item.isFilterName" class="p-sm-full mt-16 text-16 text-black-light block">{{ $t(item.name) }}</span>
              <span v-if="item.isSeparator" class="block border-b-8 border-surface-overlay -mt-2"/>

              <component v-if="!item.isFilterName && !item.isSeparator && !item.isCancel"
                         :is="itemTemplate"
                         :countable="countable"
                         :isMultiple="isMultiple"
                         :activeSlug="activeSlug"
                         :selected="selected"
                         :item="item"
                         :key="item.id + ' ' +  item.name"
                         @click.native.stop.prevent="onSelect(item.id + '-' + item.name,item.children === undefined)"/>
              <button v-if="item.isCancel" @click="onSkip" class="flex text-16 w-full text-left py-12 px-10 font-bold mb-1 items-center">
                {{ $t('deepselector.skip') }}
              </button>
            </DynamicRecycleViewItem>
          </template>
        </DynamicRecycleView>

      </div>

    </div>
  </div>
</template>

<script>
import DeepSelectorItem from '~/components/DeepSelector/DeepSelectorItem'
import DeepSelectorItemNav from '~/components/DeepSelector/DeepSelectorItemNav'
import Button from "@/components/buttons/Button.vue"
import DynamicRecycleView from "@/components/RecycleView/DynamicRecycleView.vue"
import DynamicRecycleViewItem from "@/components/RecycleView/DynamicRecycleViewItem.vue"

export default {
  components: {DynamicRecycleViewItem, DynamicRecycleView, DeepSelectorItem, DeepSelectorItemNav, Button},
  props: {
    isPreviewList: Boolean,
    fullList: Array,
    isFiltered: Boolean,
    hasCancel: Boolean,
    itemTemplate: String,
    hint: String,
    listCount: Object,
    countable: Boolean,
    data: {
      type: Array,
      default: () => [],
      required: true
    },
    selected: {
      type: [Object, Array]
    },
    activeSlug: {
      type: null
    },
    hasFooter: {
      type: Boolean,
      default: true
    },
    isMultiple: Boolean
  },
  watch: {
    data() {
      this.createList();
    },
    listCount() {
      this.mergedList = this.mergedList.map(item => {
        return {
          ...item,
          loading: this.countable && this.listCount?.loading,
          count: this.listCount && this.listCount[item.id] ? this.listCount[item.id] : 0,
        }
      })
    }
  },
  mounted() {
    this.createList();
  },
  data() {
    return {
      hasMore: true,
      hasSelection: false,
      list: [],
      mainList: {
        children: []
      },
      mergedList: []
    };
  },
  computed: {
    hasFullList() {
      return this.isPreviewList && !this.isFiltered && !this.mainList?.seeAllDisabled
    }
  },
  methods: {
    onFullList() {
      this.hasMore = false
      this.$emit('all');
    },
    createList() {
      this.mergedList = []
      const selectedList = [];

      const dataList = this.isPreviewList && this.fullList && this.fullList.length ? this.fullList : this.data;

      if (this.itemTemplate !== 'DeepSelectorItemNav' && ((dataList && dataList.length > 3) || (dataList && dataList.length && dataList[0].children && dataList[0].children.length > 10))) {
        dataList.forEach(item => {
          item.children.forEach(child => {
            if (this.selected && this.selected.length && this.selected.some(item => (item.id + '-' + item.name) === (child.id + '-' + child.name))) {
              selectedList.push(child);
            } else if (this.selected && (this.selected.id + '-' + this.selected.name) === (child.id + '-' + child.name)) {
              selectedList.push(child);
            }
          });
        });
      }

      let list;
      if (selectedList.length) {

        this.hasSelection = true;
        list = [{children: selectedList, seeAllDisabled: true}, ...this.data];
      } else {
        this.hasSelection = false;
        list = [...this.data];
      }

      this.list = list


      if (list.length) {

        list.forEach((filter, index) => {
          if(this.hasSelection && index === 1) {
            this.mergedList.push({
              key: `${index}-separator`,
              isSeparator: true
            })
          }
          if (filter.name && this.data.length > 1) {
            this.mergedList.push({
              key: `${index}-name`,
              isFilterLabel: true,
              name: filter.name
            })
          }
          this.mergedList = [
            ...this.mergedList,
            ...filter.children.map(item => {
              return {
                ...item,
                loading: this.countable && this.listCount?.loading,
                count: this.listCount && this.listCount[item.id] ? this.listCount[item.id] : 0,
                key:`${index}-${item.id}`
              }
            })]
          if(this.hasCancel) {
            this.mergedList.push({
              key: `${index}-cancel`,
              isCancel: true
            })
          }
        })
      }
    },
    onSelect(slug, isLast) {
      this.$emit('select', slug, isLast);
    },
    onSkip() {
      this.$emit('skip');
    }
  }
};
</script>
