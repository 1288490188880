<template>
  <div class="bg-no-repeat bg-center" :class="{'bg-cover': !contain, 'bg-contain': contain, 'lazyload' : lazy}" data-sizes="auto" :data-bgset="_srcset ? _srcset.sources : null">
    <slot/>
  </div>
</template>
<script>
import {nearestAspectRatio} from "assets/js/utils/aspect-ratio";
import {BREAKPOINTS} from "assets/js/constants/constant";
import imgProxyURL from "assets/js/utils/imgProxy";

export default {
  props: {
    sizes: {
      type: Array
    },
    src: {
      type: String,
      default: '',
    },
    resizing: {
      type: String,
      default: 'fill'
    },
    gravities: {
      type: Array,
      default() {
        return ['ce'];
      }
    },
    contain: Boolean,
  },
  watch : {
    src() {
      this.$el.classList.add('lazyload');
    }
  },
  computed: {
    lazy() {
      return typeof window !== 'undefined' && process.client;
    },
    _srcset() {

      if(!this.src) {
        return;
      }

      const keys = Object.keys(BREAKPOINTS);
      const classNames = this.sizes.reduce((sizes, size, index) => {
        const s = size.split(':');
        return sizes + ' ' + nearestAspectRatio(s[0], s[1], index > 0 ? keys[index] + ':' : '');
      }, '');

      const sources = this.sizes.reduce((sizes, size, index) => {
        const s = size.split(':');
        const gravity = this.gravities[Math.min(this.gravities.length - 1, index)];
        const webp = process.env.imgProxy + imgProxyURL(this.src, s[0], s[1], 'webp', this.resizing, gravity) + ' [(min-width: ' + BREAKPOINTS[keys[index]] + ')][type=image/webp]';
        const jpg = process.env.imgProxy + imgProxyURL(this.src, s[0], s[1], 'jpg', this.resizing, gravity) + ' [(min-width: ' + BREAKPOINTS[keys[index]] + ')]';
        return [...sizes, webp + ' | ' + jpg]
      }, []);

      const s = this.sizes[0].split(':');

      const base = process.env.imgProxy + imgProxyURL(this.src, s[0], s[1], 'jpg', this.resizing)
      const final = sources.reverse().join(' | ') + ' | ' + base;

      return {
        classNames,
        sources: final,
        base
      }
    },
  },
};
</script>
