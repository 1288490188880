<template>
  <div v-html="text" @click="getLink"/>
</template>

<script>
/* *
 * In the translation file put the links between <button data-to='yourLink'></button>
 * yourLink is like page-cgv or shop-xavierdelerue
* */
export default {
  name: "ClickableText",
  props: {
    text: String
  },
  methods: {
    getLink(event) {


      let to = event.target ? event.target.dataset.to : null;

      if(to) {
        event.preventDefault();
        event.stopPropagation();

        const path = this.$router.resolve(this.localePath({name: to}));
        //window['console'].log('path',window.location.origin,path.href);
        if(this.$capacitor) {
          this.$capacitor.open('https://www.everide.app' + path.href + '?iframe=1');
        }else {
          window.open(path.href, '_blank');
        }

      }
    }
  }
}
</script>

<style scoped>

</style>
