<template>
  <div class="loading loading-circular text-center flex items-center justify-center">
    <span class="loading-spinner loading-spinner-circular">
      <svg class="loading-circular" viewBox="25 25 50 50">
        <circle cx="50" cy="50" r="20" fill="none" />
      </svg>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Loading'
};
</script>

<style scoped lang="scss">
.loading {
  position: relative;
  color: inherit;
  font-size: 0;
  vertical-align: middle;

  &-spinner {
    position: relative;
    display: inline-block;
    width: 20px;
    // compatible for 1.x, users may set width or height in root element
    max-width: 100%;
    height: 20px;
    max-height: 100%;
    vertical-align: middle;

    &-circular {
      animation-duration: 2s;
    }
  }

  &-circular {
    width: 100%;
    height: 100%;

    circle {
      animation: circular 1.5s ease-in-out infinite;
      stroke: currentColor;
      stroke-width: 3;
      stroke-linecap: round;
    }
  }
}
@keyframes circular {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120;
  }
}
</style>
