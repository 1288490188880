var render = function render(){var _vm=this,_c=_vm._self._c;return _c('picture',{class:(_vm.resizing === 'fill' ? _vm.ratio : '') +
                   (_vm.border ? ' border border-solid border-gray-400' : '') +
                   (!_vm.noBg ? ' bg-surface-overlay' : '') +
                   (!_vm.isChat && _vm.resizing !== 'cover' ? ' relative' : '') +
                   (!_vm.isChat && _vm.resizing !== 'cover' ? ' absolute' : '') +
                   (_vm.zoom === true ? ' swiper-zoom-container' : '') +
                   (!_vm.isChat ? ' swiper-zoom-target e-picture-img block min-h-0 w-full' : ''),attrs:{"src":_vm.empty,"alt":_vm.alt}},[_vm._l((_vm._srcset.sources),function(source,index){return _c('source',{key:_vm._srcset.base + index,attrs:{"data-srcset":!_vm.forceLoad ? source.srcset : false,"srcset":_vm.forceLoad ? source.srcset : false,"type":source.type,"media":source.media}})}),_vm._v(" "),_c('img',{ref:"img",class:{
        'h-full object-cover' : this.resizing === 'fill',
        'h-full object-contain' : this.resizing === 'fit-contain',
        'lazyload' : _vm.lazy && !_vm.forceLoad,
        'h-full object-cover object-center' : this.resizing === 'cover',
        'w-full text-transparent border-0' : !_vm.isChat,
        'object-bottom': _vm.bottom
      },attrs:{"width":_vm.attributes.width,"height":_vm.attributes.height,"src":_vm.forceLoad ? _vm._srcset.base : _vm.empty,"data-src":!_vm.forceLoad ? _vm._srcset.base : false,"alt":_vm.alt},on:{"load":_vm.onLoad}}),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }